import { media } from '@/styles/media'
import { useEffect, useState } from 'react'

export enum DeviceType {
  Mobile = 'MOBILE',
  Tablet = 'TABLET',
  Desktop = 'DESKTOP'
}

export function useDeviceType(): { deviceType: DeviceType; width: number } {
  const [size, setSize] = useState({ width: 0, height: 0 })
  const [deviceType, setDeviceType] = useState(DeviceType.Mobile)

  useEffect(() => {
    const handleResize = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight })
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const getDeviceType = () => {
      if (window?.matchMedia(media.desktop.replace('@media ', '')).matches) {
        return DeviceType.Desktop
      }
      if (
        window?.matchMedia(media.tabletLandscape.replace('@media ', '')).matches
      ) {
        return DeviceType.Tablet
      }
      return DeviceType.Mobile
    }

    setDeviceType(getDeviceType())
  }, [size])

  return {
    deviceType,
    width: size.width
  }
}
