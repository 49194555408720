import React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'

import Layout from '@/components/layouts/Layout'
import LatestNews from '@/components/pages/home/LatestNews'
import OurMission from '@/components/pages/home/OurMission'
import OurServices from '@/components/pages/home/OurServices'
import WorkLifeAtIchigo from '@/components/pages/home/WorkLifeAtIchigo'
import { Container } from '@/components/styled/Container'
import InnerContainer from '@/components/styled/InnerContainer'
import { Helmet } from 'react-helmet'
import { PrismicAllNews } from '@/components/generic/NewsCard'
import OurVision from '@/components/pages/home/OurVission'
import ContactUs from '@/components/generic/ContactUs'
import Partners from '@/components/pages/about/Partners'
import { useTranslation } from 'react-i18next'

const CustomContainer = styled(Container)`
  padding: 0;
`

export default function Home({ data }: { data: PrismicAllNews }) {
  const { t } = useTranslation()

  return (
    <Layout
      LayoutContainer={CustomContainer}
      immersiveNavbar
      pageTitle={t('seo.home.title')}
    >
      <Helmet>
        <meta name="description" content={t('seo.home.meta_description')} />
        <link rel="canonical" href="https://ichigo.com" />
      </Helmet>
      <InnerContainer
        style={{
          flexDirection: 'column',
          margin: '0 auto'
        }}
      >
        <OurMission />
        <OurServices />
        <OurVision />
        <Partners bgColor="none" noBorder />
        <WorkLifeAtIchigo />
        <LatestNews data={data} />
        <ContactUs />
      </InnerContainer>
    </Layout>
  )
}

export const query = graphql`
  {
    allPrismicNew(
      limit: 3
      sort: { fields: [data___article_date], order: DESC }
    ) {
      edges {
        node {
          id
          data {
            image {
              url
            }
            heading {
              text
            }
            translated_heading {
              text
            }
            subheading {
              text
            }
            translated_subheading {
              text
            }
            is_internal_article
            original_language
            category
            slug
            date
            excerpt {
              text
            }
            link {
              url
            }
          }
        }
      }
    }
  }
`
